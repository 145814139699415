import React, {useContext, useRef} from "react";
import Hero, {HeroInterface} from "./Hero";
import {Position} from "../model/Position";
import {Direction} from "../model/Direction";
import {LevelData} from "../data/LevelData";
import Npcs from "./Npcs";
import Doors from "./Doors";
import Collectibles from "./Collectibles";
import Steps from "./Steps";
import Floor from "./Floor";
import {Location} from "../model/Location";

interface BoardProps {
    levelData: LevelData,
    targetDoorId: string | null
}

const Board = (props: BoardProps) => {

    console.log("BOARD");

    const {levelData, targetDoorId} = props;

    const heroInterface = useRef<HeroInterface>(null);
    const onWalkFinishedRef = useRef<() => void>(() => {
    });

    const locked = useRef<boolean>(false);

    const npcs = levelData.npcs.map(npc => Location.fromData(npc));
    const doors = levelData.doors.map(door => Location.fromData(door));
    const collectibles = levelData.collectibles.map(col => Location.fromData(col));
    const steps = levelData.steps.map(step => Position.fromData(step));
    const targetDoor = doors.find(door => door.id === targetDoorId);
    const heroStart = targetDoor ? targetDoor.getEngagingPosition() : Position.fromData(levelData.heroStart);

    const unlock = () => {
        locked.current = false;
    }

    const acquireLock = () => {
        if (locked.current) return false;
        locked.current = true;
        return true;
    }

    const walkHero = (position: Position, direction: Direction, onWalkFinished: () => void) => {
        onWalkFinishedRef.current = onWalkFinished;
        heroInterface.current!.walk(position, direction);
    }

    const onWalkFinished = () => {
        onWalkFinishedRef.current();
        unlock();
    }

    //TODO to component



    return (
        <React.Fragment>
            <Floor levelImage={levelData.image} steps={steps}/>
            <Steps steps={steps} acquireLock={acquireLock} walkHero={walkHero}/>
            <Doors doors={doors} acquireLock={acquireLock} walkHero={walkHero}/>
            <Npcs npcs={npcs} acquireLock={acquireLock} walkHero={walkHero}/>
            <Collectibles collectibles={collectibles} acquireLock={acquireLock} walkHero={walkHero}/>
            <Hero ref={heroInterface} heroStart={heroStart} steps={steps} onWalkFinished={onWalkFinished}/>
        </React.Fragment>
    )

}

export default Board;