import {PositionData} from "../data/PositionData";
import {Direction} from "./Direction";

export class Position {

    private readonly _col: number;
    private readonly _row: number;

    constructor(col: number, row: number) {
        this._col = col;
        this._row = row;
    }

    public static fromData(data: PositionData): Position {
        return new Position(data.col, data.row);
    }

    get col(): number {
        return this._col;
    }

    get row(): number {
        return this._row;
    }

    equals(position: Position) {
        return this.col === position.col && this.row === position.row;
    }

    getDirectionToTarget(target: Position): Direction {
        const colDelta = Math.sign(target.col - this.col);
        const rowDelta = Math.sign(target.row - this.row);
        return Direction.fromDeltas(colDelta, rowDelta)
    }

    getPathToTarget(target: Position, availability: Position[]): Position[] {
        const path = this.aStarToTarget(target, availability);
        return Position.simplifyPath(path!);
    }

    private aStarToTarget = (target: Position, availability: Position[]): Position[] | null => {
        const openSet = new Set<Position>();
        const cameFrom = new Map<string, Position>();
        const gScore = new Map<string, number>();
        const fScore = new Map<string, number>();

        const startKey = this.getKey();
        gScore.set(startKey, 0);
        fScore.set(startKey, this.getManhattanDistance(target));
        openSet.add(this);

        while (openSet.size > 0) {
            let current: Position | undefined;
            let currentKey: string | undefined = undefined;

            openSet.forEach(pos => {
                const key = pos.getKey();
                if (!current || (fScore.get(key) ?? Infinity) < (fScore.get(currentKey ?? "") ?? Infinity)) {
                    current = pos;
                    currentKey = key;
                }
            });

            if (!current) throw new Error("Error while retrieving the path");
            if (current.equals(target)) {
                return Position.rebuildPath(cameFrom, current);
            }

            openSet.delete(current);

            for (const direction of Direction.DIRECTIONS) {

                const neighbor = new Position(current.col + direction.colDelta, current.row + direction.rowDelta);

                if (!neighbor.isAvailable(availability)) continue;
                if (direction.colDelta !== 0 && direction.rowDelta !== 0 && !current.isDiagonalValid(direction, availability)) continue;

                const tentative_gScore = (gScore.get(current.getKey()) ?? Infinity) + 1;

                const neighborKey = neighbor.getKey();
                if (tentative_gScore < (gScore.get(neighborKey) ?? Infinity)) {
                    cameFrom.set(neighborKey, current);
                    gScore.set(neighborKey, tentative_gScore);
                    fScore.set(neighborKey, tentative_gScore + neighbor.getManhattanDistance(target));
                    openSet.add(neighbor);
                }
            }
        }

        return null;
    }

    private static rebuildPath(cameFrom: Map<string, Position>, current: Position): Position[] {
        const totalPath = [current];
        while (cameFrom.has(current.getKey())) {
            const step = cameFrom.get(current.getKey());
            totalPath.unshift(step!);
            current = step!;
        }
        return totalPath;
    }

    private static simplifyPath(path: Position[]): Position[] {
        if (path.length <= 1) return path;

        const simplified = [path[0]];

        for (let i = 1; i < path.length - 1; i++) {
            const prev = simplified[simplified.length - 1];
            const current = path[i];
            const next = path[i + 1];

            const isStraightLine =
                (next.row - current.row) * (current.col - prev.col) ===
                (next.col - current.col) * (current.row - prev.row);
            if (!isStraightLine) simplified.push(current);
        }

        simplified.push(path[path.length - 1]);
        return simplified.slice(1);
    }

    private isDiagonalValid(directionDeltas: Direction, availability: Position[]): boolean {
        const horizontal = new Position(this.col + directionDeltas.colDelta, this.row);
        const vertical = new Position(this.col, this.row + directionDeltas.rowDelta);
        return horizontal.isAvailable(availability) && vertical.isAvailable(availability);
    }

    private getKey = (): string => {
        return this.col + "-" + this.row;
    }

    private getManhattanDistance = (target: Position): number => {
        return Math.abs(this.col - target.col) + Math.abs(this.row - target.row);
    }

    private isAvailable = (availability: Position[]): boolean => {
        return availability.some(available => available.equals(this));
    }

}