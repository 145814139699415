import React, {createContext, useContext, useEffect, useState} from "react";
import Fetcher from "../data/Fetcher";
import {SessionData} from "../data/SessionData";
import {CanvasContext} from "./Canvas";

interface SessionContextType {
    levelId: string,
    loadLevel: (levelId: string) => void,
    heroId: string,
    unlock: (locks: string[]) => void,
    isLocked: (locks: string[]) => boolean,
}

export const SessionContext = createContext<SessionContextType | undefined>(undefined);

const Session = (props: any) => {

    console.log("SESSION")

    const {startLoading} = useContext(CanvasContext)!;

    const [levelId, setLevelId] = useState<string | undefined>();
    const [heroId, setHeroId] = useState<string | undefined>();
    const [milestones, setMilestones] = useState<string[] | undefined>();

    useEffect(() => {
        new Fetcher<SessionData>("/data/session.json")
            .onSuccess((sessionResponse: SessionData) => {
                setLevelId(sessionResponse.levelId);
                setHeroId(sessionResponse.heroId);
                setMilestones(sessionResponse.milestones);
            })
            .fetch();
    }, []);

    if (!levelId || !milestones || !heroId) {
        return;
    }

    const loadLevel = (levelId: string): void => {
        startLoading("");
        setLevelId(levelId);
    }

    const unlock = (locks: string[]) => {
        console.log(locks);
        setMilestones(milestones.concat(locks));
    }

    const isLocked = (blocks: string[]) => {
        return blocks.some(block => !milestones.includes(block));
    }

    const sessionContext = {
        levelId: levelId,
        loadLevel: loadLevel,
        heroId: heroId,
        unlock: unlock,
        isLocked: isLocked
    }

    return (
        <SessionContext.Provider value={sessionContext}>
            {props.children}
        </SessionContext.Provider>
    )

}

export default Session;