import {DoorData} from "../data/DoorData";
import {Item} from "./Item";

export class Door extends Item {

    private readonly _openImage: string;
    private readonly _closeImage: string;
    private readonly _targetLevelId: string;
    private readonly _targetDoorId: string;
    private readonly _lockedBy: string[];
    private readonly _unlocks: string[];
    private readonly _lockedMessage: string[];


    constructor(
        id: string,
        name: string,
        scale: number,
        openImage: string,
        closeImage: string,
        targetLevelId: string,
        targetDoorId: string,
        lockedBy: string[],
        unlocks: string[],
        lockedMessage: string[]
    ) {
        super(id, name, scale);
        this._openImage = openImage;
        this._closeImage = closeImage;
        this._targetLevelId = targetLevelId;
        this._targetDoorId = targetDoorId;
        this._lockedBy = lockedBy;
        this._unlocks = unlocks;
        this._lockedMessage = lockedMessage;
    }

    public static fromData(data: DoorData): Door {
        return new Door(
            data.id,
            data.name,
            data.scale,
            data.openImage,
            data.closeImage,
            data.targetLevelId,
            data.targetDoorId,
            data.lockedBy,
            data.unlocks,
            data.lockedMessage
        )
    }

    get openImage(): string {
        return this._openImage;
    }

    get closeImage(): string {
        return this._closeImage;
    }

    get targetLevelId(): string {
        return this._targetLevelId;
    }

    get targetDoorId(): string {
        return this._targetDoorId;
    }

    get lockedBy(): string[] {
        return this._lockedBy;
    }

    get unlocks(): string[] {
        return this._unlocks;
    }

    get lockedMessage(): string[] {
        return this._lockedMessage;
    }
}