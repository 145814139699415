import {NpcData} from "../data/NpcData";
import {Dialog} from "./Dialog";
import {Avatar} from "./Avatar";

export class Npc extends Avatar {

    private readonly _dialogs: Dialog[];

    constructor(
        id: string,
        name: string,
        scale: number,
        images: string[],
        dialogs: Dialog[]
    ) {
        super(id, name, scale, images);
        this._dialogs = dialogs;
    }

    public static fromData(data: NpcData): Npc {
        const dialogs = data.dialogs.map(dialog => Dialog.fromData(dialog));
        return new Npc(
            data.id,
            data.name,
            data.scale,
            data.images,
            dialogs
        )
    }

    getDialog(levelId: string, isLocked: (locks: string[]) => boolean): Dialog {
        const dialog = this._dialogs.filter(dialog => dialog.levelId === levelId)
            .filter(dialog => !isLocked(dialog.lockedBy))
            .sort((a, b) => b.lockedBy.length - a.lockedBy.length)
            .find(() => true)
        if (dialog) return dialog;
        throw new Error("Unable to retrieve dialog");
    }

}