import React, {useContext, useEffect, useState} from "react";
import {GameContext} from "./Game";
import {Direction} from "../model/Direction";
import {Status} from "../model/Status";

interface AvatarFrameProps {
    status: Status,
    direction: Direction,
    size: number,
    images: string[]
}

const AvatarFrame = (props: AvatarFrameProps) => {

    const {sprite, cache} = useContext(GameContext)!;

    const {
        status,
        direction,
        size,
        images
    } = props;

    const {
        baseWidth,
        baseHeight,
        frameTime
    } = sprite;

    const sequence = sprite.getSequence(status, direction);

    const [spriteSequenceIndex, setSpriteSequenceIndex] = useState<number>(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSpriteSequenceIndex((prev) => {
                if (prev === sequence.length - 1) {
                    return 0;
                } else {
                    return prev + 1;
                }
            });
        }, frameTime);
        return () => clearInterval(intervalId);
    }, []);

    const spriteCol = sequence[spriteSequenceIndex].col;
    const spriteRow = sequence[spriteSequenceIndex].row;
    const spritePosX = spriteCol * baseWidth * size / baseHeight * (-1);
    const spritePosY = spriteRow * baseWidth * size / baseHeight * (-1);

    const imageStyle = (imageSrc: string) => {
        const image = cache.toHtmlImage(imageSrc)
        const spriteWidth = image.width / baseWidth * size;
        const spriteHeight = image.height / baseHeight * size;
        return {
            width: size,
            height: size,
            backgroundImage: "url(" + image.src + ")",
            backgroundPositionX: spritePosX,
            backgroundPositionY: spritePosY,
            backgroundSize: spriteWidth + "px " + spriteHeight + "px",
        }
    }

    return images.map((imageSrc, index) => {
        return <div key={index} style={imageStyle(imageSrc)}/>
    })

}

export default AvatarFrame;