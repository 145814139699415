import {CollectibleData} from "../data/CollectibleData";
import {Item} from "./Item";

export class Collectible extends Item {

    private readonly _image: string;
    private readonly _unlocks: string[];

    constructor(
        id: string,
        name: string,
        scale: number,
        image: string,
        unlocks: string[]
    ) {
        super(id, name, scale);
        this._image = image;
        this._unlocks = unlocks;
    }

    public static fromData(data: CollectibleData): Collectible {
        return new Collectible(data.id, data.name, data.scale, data.image, data.unlocks);
    }

    get image(): string {
        return this._image;
    }

    get unlocks(): string[] {
        return this._unlocks;
    }

}