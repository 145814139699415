import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";
import {Direction} from "../model/Direction";
import {Position} from "../model/Position";
import {GameContext} from "./Game";
import {Location} from "../model/Location";
import AvatarImage from "./AvatarImage";

interface NpcsProps {
    npcs: Location[],
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Npcs = (props: NpcsProps) => {

    console.log("NPCS")

    const {startDialog} = useContext(CanvasContext)!;
    const {levelId, unlock, isLocked} = useContext(SessionContext)!;
    const {getNpc} = useContext(GameContext)!;

    const {
        npcs,
        acquireLock,
        walkHero,
    } = props;

    const renderNpcs = () => {
        return npcs.map(((npc, index) => renderNpc(npc, index)));
    }

    const renderNpc = (location: Location, index: number) => {

        const npc = getNpc(location.id);

        const onWalkFinish = () => {
            const dialog = npc.getDialog(levelId, isLocked);
            const onFinish = dialog.unlocks.length !== 0 ? () => unlock(dialog.unlocks) : () => {
            };
            startDialog(dialog.sentences, onFinish);
        }

        const onClickHandler = () => {
            if (!acquireLock()) return;
            walkHero(location.getEngagingPosition(), location.getEngagingDirection(), onWalkFinish);
        }

        return <AvatarImage key={index}
                            avatar={npc}
                            position={location.position}
                            direction={location.direction}
                            onClickHandler={onClickHandler}/>
    }

    return renderNpcs();

}

export default Npcs;