import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import TypingText, {TypingTextInterface} from "./TypingText";
import "../styles/dialog.scss"
import {GameContext} from "./Game";

export interface DialogInterface {
    talk: (sentences: string[][], onFinish: () => void) => void;
}

const Dialog = forwardRef<DialogInterface>((props, ref) => {

    console.log("DIALOG");

    const {cache} = useContext(GameContext)!;

    const typingTextInterface = useRef<TypingTextInterface>(null);

    const dialogIndex = useRef<number>(-1);
    const dialog = useRef<string[][]>([]);
    const onFinishRef = useRef<() => void>(() => {
    });

    const [displayDialog, setDisplayDialog] = useState<boolean>(false);
    const [displayButton, setDisplayButton] = useState<boolean>(false);

    const onFinishTyping = () => {
        setDisplayButton(true);
    }

    useImperativeHandle(ref, () => ({
        talk: (sentences: string[][], onFinish: () => void) => {
            onFinishRef.current = onFinish
            dialogIndex.current = 0;
            dialog.current = sentences;
            const lines = dialog.current[dialogIndex.current];
            typingTextInterface.current!.type(lines, onFinishTyping);
            setDisplayDialog(true);
        }
    }));

    const button = () => {
        const nextDialogIndex = dialogIndex.current + 1
        const hasNext = nextDialogIndex < dialog.current.length;
        const image = hasNext ? cache.dialogNext : cache.dialogClose;
        const clear = () => {
            typingTextInterface.current!.clear();
            setDisplayButton(false);
        }
        const onClick = hasNext ?
            () => {
                clear();
                dialogIndex.current = nextDialogIndex;
                const lines = dialog.current[nextDialogIndex];
                typingTextInterface.current!.type(lines, onFinishTyping);
            } :
            () => {
                clear();
                setDisplayDialog(false);
                onFinishRef.current();
                dialogIndex.current = 0;
                dialog.current = [];
                onFinishRef.current = () => {
                };
            };
        return <div style={{backgroundImage: "url(" + image + ")"}} className={"FadeInOutButton"} onClick={onClick}/>
    }

    return (
        <div className={"Layout Dialog"} style={{display: displayDialog ? "flex" : "none"}}>
            <div style={{backgroundImage: "url(" + cache.dialogFrame + ")"}} className={"DialogFrame"}>
                <div className={"DialogText"}>
                    <TypingText ref={typingTextInterface}/>
                </div>
                {displayButton && button()}
            </div>
        </div>
    );

});

export default Dialog;