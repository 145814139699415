export class Status {

    public static IDLE = new Status("IDLE");
    public static WALKING = new Status("WALKING");

    private static STATUSES = [
        Status.IDLE,
        Status.WALKING
    ];

    private readonly _key: string;

    constructor(key: string) {
        this._key = key;
    }

    public static fromKey(key: string): Status {
        const status = this.STATUSES.find(s => s.key === key);
        if (status) return status;
        throw new Error("Status not found for key " + key);
    }

    get key(): string {
        return this._key;
    }

}