import {DialogData} from "../data/NpcData";

export class Dialog {

    private readonly _levelId: string;
    private readonly _sentences: string[][];
    private readonly _lockedBy: string[];
    private readonly _unlocks: string[];

    constructor(
        levelId: string,
        sentences: string[][],
        lockedBy: string[],
        unlocks: string[]
    ) {
        this._levelId = levelId;
        this._sentences = sentences;
        this._lockedBy = lockedBy;
        this._unlocks = unlocks;
    }

    public static fromData(data: DialogData): Dialog {
        return new Dialog(
            data.levelId,
            data.sentences,
            data.lockedBy,
            data.unlocks
        );
    }

    get levelId(): string {
        return this._levelId;
    }

    get sentences(): string[][] {
        return this._sentences;
    }

    get lockedBy(): string[] {
        return this._lockedBy;
    }

    get unlocks(): string[] {
        return this._unlocks;
    }
}