import React, {useContext} from "react";
import {LevelContext} from "./Level";
import {Position} from "../model/Position";

interface FixedProps {
    image: string,
    scale: number,
    position: Position,
    onClickHandler?: () => void
}

const FixedImage = (props: FixedProps) => {

    const {tileWidth, getItemLeft, getItemTop} = useContext(LevelContext)!;

    const {
        image,
        scale,
        position,
        onClickHandler = () => {
        }
    } = props;

    const size = tileWidth * scale;

    return <div className={"FixedImage"} onClick={onClickHandler} style={{
        width: size,
        height: size,
        backgroundImage: "url(" + image + ")",
        left: getItemLeft(position, size),
        top: getItemTop(position, size)
    }}/>

}

export default FixedImage;