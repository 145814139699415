import React, {forwardRef, useContext, useEffect, useRef} from "react";
import {LevelContext} from "./Level";
import AvatarFrame from "./AvatarFrame";
import {Position} from "../model/Position";
import {Direction} from "../model/Direction";
import {Status} from "../model/Status";
import {Avatar} from "../model/Avatar";

interface AvatarImageProps {
    avatar: Avatar,
    position: Position,
    status?: Status,
    direction: Direction,
    onClickHandler?: () => void,
    transitioning?: boolean,
}

const AvatarImage = forwardRef<HTMLDivElement, AvatarImageProps>((props, ref) => {

    const {
        tileWidth,
        getItemLeft,
        getItemTop,
        getTransitionTime,
    } = useContext(LevelContext)!;

    const {
        avatar,
        position,
        status = Status.IDLE,
        direction,
        onClickHandler,
        transitioning = false
    } = props;

    const prevPosition = useRef<Position>(position);

    useEffect(() => {
        prevPosition.current = position;
    }, [position]);

    const size = tileWidth * avatar.scale;

    const getLeft = (position: Position) => {
        return getItemLeft(position, size);
    }

    const getTop = (position: Position) => {
        return getItemTop(position, size);
    }

    const getTransition = () => {
        if (!transitioning) return "none";
        const time = getTransitionTime(prevPosition.current!, position);
        return "left " + time + "s linear, top " + time + "s linear";
    }

    return (
        <div className={"SpriteImage"} onClick={onClickHandler} ref={ref} style={{
            width: size,
            height: size,
            left: getLeft(position),
            top: getTop(position),
            transition: getTransition()
        }}>
            <AvatarFrame images={avatar.images}
                         status={status}
                         direction={direction}
                         size={size}/>
        </div>

    )
});

export default AvatarImage;