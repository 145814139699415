import React, {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {Position} from "../model/Position";
import {Direction} from "../model/Direction";
import {SessionContext} from "./Session";
import {Status} from "../model/Status";
import {GameContext} from "./Game";
import {LevelContext} from "./Level";
import AvatarImage from "./AvatarImage";

export interface HeroInterface {
    walk: (finalPosition: Position, finalDirection: Direction) => void;
}

interface HeroProps {
    heroStart: Position;
    steps: Position[];
    onWalkFinished: () => void;
}

const Hero = forwardRef<HeroInterface, HeroProps>((props, ref) => {

    console.log("HERO");

    const {heroId} = useContext(SessionContext)!;
    const {getHero} = useContext(GameContext)!;
    const {resetCamera, updateCamera} = useContext(LevelContext)!;

    const {heroStart, steps, onWalkFinished} = props;

    const spriteRef = useRef<HTMLDivElement | null>(null);
    const originalStartRef = useRef<Position | undefined>();
    const finalDirectionRef = useRef<Direction>(Direction.DOWN);

    const [path, setPath] = useState<Position[]>([]);
    const [currentPosition, setCurrentPosition] = useState<Position>(heroStart);
    const [currentStatus, setCurrentStatus] = useState<Status>(Status.IDLE);
    const [currentDirection, setCurrentDirection] = useState<Direction>(Direction.DOWN);

    useEffect(() => {
        //this is just a parent render that we should skip
        if (originalStartRef.current?.equals(heroStart)) return;
        //this is the first render
        if (!originalStartRef.current) originalStartRef.current = heroStart;
        setCurrentPosition(heroStart);
        resetCamera(heroStart);
    }, [heroStart]);

    useImperativeHandle(ref, () => ({
        walk: (finalPosition: Position, finalDirection: Direction): void => {
            if (currentPosition.equals(finalPosition)) {
                setCurrentDirection(finalDirection)
                onWalkFinished();
                return;
            }
            finalDirectionRef.current = finalDirection
            const path = currentPosition.getPathToTarget(finalPosition, steps);
            setCurrentStatus(Status.WALKING);
            setPath(path);
            return;
        }
    }));

    useEffect(() => {
        if (path.length === 0) return;

        const position = path[0];
        const direction = currentPosition.getDirectionToTarget(position);

        setCurrentPosition(position);
        setCurrentDirection(direction);
        updateCamera(position);

        const onTransitioned = () => {
            if (path.length === 1) {
                setCurrentStatus(Status.IDLE);
                setCurrentDirection(finalDirectionRef.current);
                setCurrentPosition(path[0]);
                onWalkFinished();
            }
            setPath(path.slice(1));
        }
        spriteRef.current!.addEventListener('transitionend', onTransitioned, {once: true});
    }, [path]);

    return <AvatarImage avatar={getHero(heroId)}
                        ref={spriteRef}
                        position={currentPosition}
                        status={currentStatus}
                        direction={currentDirection}
                        transitioning={currentStatus === Status.WALKING}/>

});

export default Hero;