import {GameData} from "../data/GameData";
import {AvatarData} from "../data/AvatarData";

export class Cache {

    private readonly _dialogFrame: string;
    private readonly _dialogNext: string;
    private readonly _dialogClose: string;
    private readonly _inventoryWeapons: string;
    private readonly _inventoryItems: string;
    private readonly _inventorySave: string;
    private readonly _inventoryDoor: string;

    constructor(
        dialogFrame: string,
        dialogNext: string,
        dialogClose: string,
        inventoryWeapons: string,
        inventoryItems: string,
        inventorySave: string,
        inventoryDoor: string
    ) {
        this._dialogFrame = dialogFrame;
        this._dialogNext = dialogNext;
        this._dialogClose = dialogClose;
        this._inventoryWeapons = inventoryWeapons;
        this._inventoryItems = inventoryItems;
        this._inventorySave = inventorySave;
        this._inventoryDoor = inventoryDoor;
    }

    public static async fromData(gameData: GameData, heroesData: AvatarData[]): Promise<Cache> {

        //load images
        const buffer = new Set<string>();

        //hero images
        heroesData.forEach(avatar => avatar.images.forEach(image => buffer.add(image)));

        //npc images
        gameData.npcs.forEach(npc => npc.images.forEach(image => buffer.add(image)));

        //door images
        gameData.doors.forEach(locker => {
            buffer.add(locker.openImage);
            buffer.add(locker.closeImage);
        });

        //collectible images
        gameData.collectibles.forEach(col => buffer.add(col.image));

        //level images
        gameData.levels.forEach(levelData => buffer.add(levelData.image))

        //common images
        const commonImages = gameData.images as any;
        Object.keys(commonImages).forEach(key => buffer.add(commonImages[key]));

        //load images
        const promises = Array.from(buffer).map(imageJson => this.imagePromise(imageJson));
        await Promise.all(promises);

        return new Cache(
            gameData.images.dialogFrame,
            gameData.images.dialogNext,
            gameData.images.dialogClose,
            gameData.images.inventoryWeapons,
            gameData.images.inventoryItems,
            gameData.images.inventorySave,
            gameData.images.inventoryDoor
        );
    }

    private static imagePromise(imageSrc: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const newImage = new Image();
            newImage.src = imageSrc;
            newImage.onload = () => resolve();
            newImage.onerror = reject;
        });
    }

    toHtmlImage(imageSrc: string): HTMLImageElement {
        const image = new Image();
        image.src = imageSrc;
        return image;
    }

    get dialogFrame(): string {
        return this._dialogFrame;
    }

    get dialogNext(): string {
        return this._dialogNext;
    }

    get dialogClose(): string {
        return this._dialogClose;
    }

    get inventoryWeapons(): string {
        return this._inventoryWeapons;
    }

    get inventoryItems(): string {
        return this._inventoryItems;
    }

    get inventorySave(): string {
        return this._inventorySave;
    }

    get inventoryDoor(): string {
        return this._inventoryDoor;
    }

}