import React, {forwardRef, useImperativeHandle, useState} from "react";
export interface LoadingInterface {
    start: (message: string) => void;
    finish: () => void;
}

const Loading = forwardRef<LoadingInterface>((props, ref) => {

    const [display, setDisplay] = useState<boolean>(true);
    const [message, setMessage] = useState<string>("Loading...");

    useImperativeHandle(ref, () => ({
        start: (message: string) => {
            setMessage(message);
            setDisplay(true);
        },
        finish: () => {
            setDisplay(false);
        }
    }));

    return (
        <div style={{display: display ? "flex" : "none"}} className={"Layout Loading"}>
            <p>{message}</p>
        </div>
    )

});

export default Loading;