export class Direction {

    public static UP: Direction = new Direction("UP", 1, -1);
    public static DOWN: Direction = new Direction("DOWN", -1, 1);
    public static LEFT: Direction = new Direction("LEFT", -1, -1);
    public static RIGHT: Direction = new Direction("RIGHT", 1, 1);
    public static UP_LEFT: Direction = new Direction("UP_LEFT", 0, -1);
    public static UP_RIGHT: Direction = new Direction("UP_RIGHT", 1, 0);
    public static DOWN_LEFT: Direction = new Direction("DOWN_LEFT", -1, 0);
    public static DOWN_RIGHT: Direction = new Direction("DOWN_RIGHT", 0, 1);

    public static DIRECTIONS = [
        Direction.UP,
        Direction.DOWN,
        Direction.LEFT,
        Direction.RIGHT,
        Direction.UP_LEFT,
        Direction.UP_RIGHT,
        Direction.DOWN_LEFT,
        Direction.DOWN_RIGHT
    ];

    private readonly _key: string;
    private readonly _colDelta: number;
    private readonly _rowDelta: number;

    constructor(key: string, colDelta: number, rowDelta: number) {
        this._key = key;
        this._colDelta = colDelta;
        this._rowDelta = rowDelta;
    }

    public static fromKey(key: string): Direction {
        const direction = this.DIRECTIONS.find(d => d.key === key);
        if (direction) return direction;
        throw new Error("Direction not found for key " + key);
    }

    public static fromDeltas(colDelta: number, rowDelta: number): Direction {
        const direction = this.DIRECTIONS.find(d => d.colDelta === colDelta && d.rowDelta === rowDelta);
        if (direction) return direction;
        throw new Error("Direction not found for colDelta " + colDelta + " and rowDelta " + rowDelta);
    }

    get key(): string {
        return this._key;
    }

    get colDelta(): number {
        return this._colDelta;
    }

    get rowDelta(): number {
        return this._rowDelta;
    }

}