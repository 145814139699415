import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";
import {Direction} from "../model/Direction";
import {Position} from "../model/Position";
import {Location} from "../model/Location";
import FixedImage from "./FixedImage";
import {GameContext} from "./Game";
import {LevelContext} from "./Level";

interface DoorsProps {
    doors: Location[],
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Doors = (props: DoorsProps) => {

    const {startMessage} = useContext(CanvasContext)!;
    const {unlock, isLocked} = useContext(SessionContext)!;
    const {getDoor} = useContext(GameContext)!;
    const {crossDoor} = useContext(LevelContext)!;

    const {
        doors,
        acquireLock,
        walkHero
    } = props;

    const renderDoors = () => {
        return doors.map(((door, index) => renderDoor(door, index)));
    }

    const renderDoor = (location: Location, index: number) => {

        const door = getDoor(location.id);

        const locked = isLocked(door.lockedBy);
        const closed = isLocked(door.unlocks);

        const image = locked || closed ? door.closeImage : door.openImage;

        const onWalkFinish = () => {
            if (locked) {
                startMessage(door.lockedMessage);
            } else {
                if(closed) unlock(door.unlocks);
                crossDoor(door);
            }
        }

        const onClickHandler = () => {
            if (!acquireLock()) return;
            walkHero(location.getEngagingPosition(), location.getEngagingDirection(), onWalkFinish);
        }

        return <FixedImage key={index}
                           image={image}
                           scale={door.scale}
                           position={location.position}
                           onClickHandler={onClickHandler}/>
    }

    return renderDoors();

}

export default Doors;