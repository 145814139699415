import {Item} from "./Item";
import {AvatarData} from "../data/AvatarData";

export class Avatar extends Item {

    private readonly _images: string[];

    constructor(id: string, name: string, scale: number, images: string[]) {
        super(id, name, scale);
        this._images = images;
    }

    public static fromData(data: AvatarData): Avatar {
        return new Avatar(
            data.id,
            data.name,
            data.scale,
            data.images
        )
    }

    get images(): string[] {
        return this._images;
    }
}