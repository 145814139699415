import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import {Position} from "../model/Position";
import "../styles/level.scss"
import {LevelContext} from "./Level";

export interface CameraInterface {
    resetCamera: (position: Position) => void;
    updateCamera: (position: Position) => void;
}

const Camera = forwardRef<CameraInterface, any>((props, ref) => {

    console.log("CAMERA")

    const {
        tileWidth,
        tileHeight,
        getItemLeft,
        getItemTop,
        getTransitionTime,
    } = useContext(LevelContext)!;

    const [transformation, setTransformation] = useState<string>("");
    const [transition, setTransition] = useState<string>("none");

    const prevPosition = useRef<Position | undefined>();

    const backgroundWidth = window.innerWidth;
    const backgroundHeight = window.innerHeight;
    const tilesThreshold = 2;

    useImperativeHandle(ref, () => ({
        resetCamera: (position: Position): void => {
            const transformation = getTransformation(position);
            setTransformation(transformation);
            setTransition("");
            prevPosition.current = position;
        },
        updateCamera: (position: Position): void => {
            const transformation = getTransformation(position);
            const transition = getTransition(prevPosition.current!, position);
            setTransformation(transformation);
            setTransition(transition);
            prevPosition.current = position;
        }
    }));

    const getTransformation = (position: Position) => {
        const left = getItemLeft(position);
        const top = getItemTop(position);
        let translateX = 0;
        let translateY = 0;
        //left overflow
        const leftDelta = left - tileWidth * tilesThreshold;
        if (leftDelta < 0) {
            translateX = Math.floor(Math.abs(leftDelta) / tileWidth) * tileWidth;
        }
        //right overflow
        const rightDelta = left + tileWidth * tilesThreshold;
        if (rightDelta > backgroundWidth) {
            translateX = Math.floor((backgroundWidth - rightDelta) / tileWidth) * tileWidth;
        }
        //top overflow
        const topDelta = top - tileHeight * tilesThreshold;
        if (topDelta < 0) {
            translateY = Math.floor(Math.abs(topDelta) / tileHeight) * tileHeight;
        }
        //bottom overflow
        const bottomDelta = top + tileHeight * tilesThreshold;
        if (bottomDelta > backgroundHeight) {
            translateY = Math.floor((backgroundHeight - bottomDelta) / tileHeight) * tileHeight;
        }
        return "translateX(" + translateX + "px) translateY(" + translateY + "px)";
    }

    const getTransition = (origin: Position, target: Position) => {
        const time = getTransitionTime(origin, target)
        return time + "s linear";
    }

    const cameraStyle = {
        transform: transformation,
        transition: transition
    }

    return (
        <div className={"Camera"} style={cameraStyle}>
            {props.children}
        </div>
    )

});

export default Camera;