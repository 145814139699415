import React, {useContext} from "react";
import {Position} from "../model/Position";
import {Direction} from "../model/Direction";
import {LevelContext} from "./Level";

interface StepsProps extends React.PropsWithChildren {
    steps: Position[],
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Steps = (props: StepsProps) => {

    console.log("STEPS")

    const {tileWidth, tileHeight, getItemLeft, getItemTop} = useContext(LevelContext)!;

    const {
        steps,
        acquireLock,
        walkHero
    } = props;

    const renderSteps = () => {
        return steps.map(((npc, index) => renderStep(npc, index)));
    }

    const renderStep = (position: Position, index: number) => {

        const onClickHandler = () => {
            if (!acquireLock()) return;
            walkHero(position, Direction.DOWN, () => {
            });
        }

        return (
            <div key={index} className={"Step"} style={{
                left: getItemLeft(position),
                top: getItemTop(position),
                width: tileWidth,
                height: tileHeight
            }}>
                <div onClick={onClickHandler}/>
            </div>
        )
    }

    return renderSteps();


}

export default Steps;