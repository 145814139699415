import React, {createContext, useRef} from "react";
import Game from "./Game";
import Level from "./Level";
import Inventory from "./Inventory";
import Session from "./Session";
import Loading, {LoadingInterface} from "./Loading";
import Message, {MessageInterface} from "./Message";
import Dialog, {DialogInterface} from "./Dialog";

interface CanvasContextType {
    startLoading: (message: string) => void,
    finishLoading: () => void,
    startDialog: (sentences: string[][], onFinish: () => void) => void,
    startMessage: (message: string[]) => void,
    closeFullScreen: () => void
}

export const CanvasContext = createContext<CanvasContextType | undefined>(undefined);

interface CanvasProps {
    closeFullScreen: () => void;
}

const Canvas = (props: CanvasProps) => {

    const loadingInterface = useRef<LoadingInterface>(null);
    const dialogInterface = useRef<DialogInterface>(null);
    const messageInterface = useRef<MessageInterface>(null);

    const startLoading = (message: string) => {
        loadingInterface.current!.start(message);
    }

    const finishLoading = () => {
        loadingInterface.current!.finish();
    }

    const startDialog = (sentences: string[][], onFinish: () => void) => {
        dialogInterface.current!.talk(sentences, onFinish);
    }

    const startMessage = (message: string[]) => {
        messageInterface.current!.message(message);
    }

    const canvasContext = {
        startLoading: startLoading,
        finishLoading: finishLoading,
        startDialog: startDialog,
        startMessage: startMessage,
        closeFullScreen: props.closeFullScreen
    }

    return (
        <CanvasContext.Provider value={canvasContext}>
            <div className={"Canvas"}>
                <Loading ref={loadingInterface}/>
                <Session>
                    <Game>
                        <Dialog ref={dialogInterface}/>
                        <Message ref={messageInterface}/>
                        <Level/>
                        <Inventory/>
                    </Game>
                </Session>
            </div>
        </CanvasContext.Provider>

    )
}

export default Canvas;