import React, {useContext} from "react";
import {Position} from "../model/Position";
import {LevelContext} from "./Level";
import {GameContext} from "./Game";

export interface FloorProps {
    levelImage: string,
    steps: Position[]
}

const Floor = (props: FloorProps) => {

    console.log("FLOOR")

    const {cache} = useContext(GameContext)!;

    const {
        tileWidth,
        tileHeight,
        getItemLeft,
        getItemTop
    } = useContext(LevelContext)!;

    const {levelImage, steps} = props;

    const minCol = steps.reduce((min, pos) => pos.col < min ? pos.col : min, steps[0].col);
    const maxCol = steps.reduce((max, pos) => pos.col > max ? pos.col : max, steps[0].col);
    const minRow = steps.reduce((min, pos) => pos.row < min ? pos.row : min, steps[0].row);
    const maxRow = steps.reduce((max, pos) => pos.row > max ? pos.row : max, steps[0].row);

    const tiles: Position[] = [];
    for (let col = minCol - 1; col <= maxCol + 1; col++) {
        for (let row = minRow - 1; row <= maxRow + 1; row++) {
            tiles.push(new Position(col, row));
        }
    }

    const renderTiles = () => {
        return tiles.map(((position, index) => renderTile(position, index)));
    }

    const renderTile = (position: Position, index: number) => {

        const span = "{" + position.col + "," + position.row + "}";

        return (
            <div className={"Tile"} key={index} style={{
                left: getItemLeft(position),
                top: getItemTop(position),
                width: tileWidth,
                height: tileHeight
            }}>
                <div>
                    <span>{span}</span>
                </div>
            </div>
        )
    }

    const htmlImage = cache.toHtmlImage(levelImage);
    const aspectRatio = htmlImage.width / htmlImage.height;

    const floorByStyle = {
        backgroundImage: "url(" + levelImage + ")",
        aspectRatio: aspectRatio,
        width: "1000px"
    }

    return (
        <React.Fragment>
            <div style={floorByStyle} className={"FloorBg"}/>
            {renderTiles()}
        </React.Fragment>
    );

}

export default Floor;