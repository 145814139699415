import React, {forwardRef, useContext, useImperativeHandle, useRef, useState} from "react";
import TypingText, {TypingTextInterface} from "./TypingText";
import "../styles/message.scss"
import {GameContext} from "./Game";

export interface MessageInterface {
    message: (message: string[]) => void;
}

const Message = forwardRef<MessageInterface>((props, ref) => {

    const {cache} = useContext(GameContext)!;

    const typingTextInterface = useRef<TypingTextInterface>(null);

    const [displayMessage, setDisplayMessage] = useState<boolean>(false);
    const [displayButton, setDisplayButton] = useState<boolean>(false);

    const onFinishTyping = () => {
        setDisplayButton(true);
    }

    useImperativeHandle(ref, () => ({
        message: (message: string[]) => {
            setDisplayMessage(true);
            typingTextInterface.current!.type(message, onFinishTyping);
        }
    }));

    const button = () => {
        const onClick = () => {
            typingTextInterface.current!.clear();
            setDisplayButton(false);
            setDisplayMessage(false);
        };
        return <div style={{backgroundImage: "url(" + cache.dialogClose + ")"}} className={"FadeInOutButton"}
                    onClick={onClick}/>
    }

    return (
        <div className={"Layout Message"} style={{display: displayMessage ? "flex" : "none"}}>
            <div className={"MessageText"}>
                <TypingText ref={typingTextInterface}/>
            </div>
            {displayButton && button()}
        </div>
    );

});

export default Message;