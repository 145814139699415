import {Position} from "./Position";
import {Direction} from "./Direction";
import {SpriteData} from "../data/GameData";
import {Status} from "./Status";

export class Sprite {

    private readonly _baseWidth: number;
    private readonly _baseHeight: number;
    private readonly _frameTime: number
    private readonly _sequences: Map<Status, Map<Direction, Position[]>>;

    constructor(
        baseWidth: number,
        baseHeight: number,
        frameTime: number,
        sequences: Map<Status, Map<Direction, Position[]>>
    ) {
        this._baseWidth = baseWidth;
        this._baseHeight = baseHeight;
        this._frameTime = frameTime;
        this._sequences = sequences;
    }

    public static fromData(data: SpriteData): Sprite {
        return new Sprite(
            data.baseWidth,
            data.baseHeight,
            data.frameTime,
            this.getSequences(data)
        )
    }

    private static getSequences(data: SpriteData): Map<Status, Map<Direction, Position[]>> {
        const sequenceRows = data.sequenceRows;
        const sequences = new Map<Status, Map<Direction, Position[]>>();
        for (const status in sequenceRows) {
            const directions = new Map<Direction, Position[]>();
            for (const direction in sequenceRows[status]) {
                const row = sequenceRows[status][direction];
                const positions = this.positionsInBetween(data.cols, row);
                directions.set(Direction.fromKey(direction), positions);
            }
            sequences.set(Status.fromKey(status), directions);
        }
        return sequences;
    }

    private static positionsInBetween(cols: number, row: number): Position[] {
        return Array.from({length: cols}, (_, col) => new Position(col, row));
    }

    get baseWidth(): number {
        return this._baseWidth;
    }

    get baseHeight(): number {
        return this._baseHeight;
    }

    get frameTime(): number {
        return this._frameTime;
    }

    public getSequence(status: Status, direction: Direction): Position[] {
        const sequence = this._sequences.get(status)?.get(direction);
        if (sequence) return sequence;
        throw new Error("Positions not found for status " + status + " and direction " + direction);
    }

}