import React, {createContext, useContext, useEffect, useRef} from "react";
import {SessionContext} from "./Session";
import {CanvasContext} from "./Canvas";
import {Position} from "../model/Position";
import Board from "./Board";
import {Door} from "../model/Door";
import {GameContext} from "./Game";
import "../styles/level.scss"
import Camera, {CameraInterface} from "./Camera";

interface LevelContextType {
    tileWidth: number,
    tileHeight: number,
    getItemLeft: (position: Position, size?: number) => number,
    getItemTop: (position: Position, size?: number) => number,
    getTransitionTime: (origin: Position, target: Position) => number,
    resetCamera: (position: Position) => void,
    updateCamera: (position: Position) => void,
    crossDoor: (door: Door) => void
}

export const LevelContext = createContext<LevelContextType | undefined>(undefined);

const Level = () => {

    console.log("LEVEL")

    const {finishLoading} = useContext(CanvasContext)!;
    const {levelId, loadLevel} = useContext(SessionContext)!;
    const {getLevel} = useContext(GameContext)!;

    const cameraInterface = useRef<CameraInterface>(null);
    const targetDoorId = useRef<string | null>(null);

    useEffect(() => {
        finishLoading();
    }, [levelId]);

    const levelData = getLevel(levelId);

    const backgroundWidth = window.innerWidth;
    const backgroundHeight = window.innerHeight;

    const tileWidth = backgroundWidth * levelData.tileRatio;
    const tileHeight = tileWidth * Math.tan((30 * Math.PI) / 180);

    const leftOffset = (backgroundWidth / 2) - (tileWidth / 2);
    const topOffset = (backgroundHeight / 2) - (tileHeight / 2);

    const getTileLeft = (position: Position) => {
        return ((position.row + position.col) / 2) * tileWidth + leftOffset;
    }

    const getTileTop = (position: Position) => {
        return ((position.row - position.col) / 2) * tileHeight + topOffset;
    }

    const getItemLeft = (position: Position, size?: number) => {
        let itemLeftOffset = 0;
        if (size) {
            itemLeftOffset = (tileWidth - size) / 2;
        }
        return getTileLeft(position) + itemLeftOffset;
    }

    const getItemTop = (position: Position, size?: number) => {
        let itemTopOffset = 0;
        if (size) {
            //for center aligned ->  itemTopOffset = (tileHeight - size) / 2;
            itemTopOffset = (tileHeight / 2) - size;
        }
        return getTileTop(position) + itemTopOffset;
    }

    const getTransitionTime = (origin: Position, target: Position) => {
        const deltaX = getTileLeft(target) - getTileLeft(origin);
        const deltaY = getItemTop(target) - getItemTop(origin);
        const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
        return distance / (tileWidth * 2);
    }

    const crossDoor = (door: Door) => {
        targetDoorId.current = door.targetDoorId;
        loadLevel(door.targetLevelId);
    }

    const resetCamera = (position: Position) => {
        cameraInterface.current!.resetCamera(position);
    }

    const updateCamera = (position: Position) => {
        cameraInterface.current!.updateCamera(position);
    }

    const levelContext = {
        tileWidth: tileWidth,
        tileHeight: tileHeight,
        getItemLeft: getItemLeft,
        getItemTop: getItemTop,
        getTransitionTime: getTransitionTime,
        resetCamera: resetCamera,
        updateCamera: updateCamera,
        crossDoor: crossDoor
    }

    return (

        <LevelContext.Provider value={levelContext}>
            <div className={"Level"}>
                <Camera ref={cameraInterface}>
                    <Board levelData={levelData} targetDoorId={targetDoorId.current}/>
                </Camera>
            </div>
        </LevelContext.Provider>
    )

}

export default Level;