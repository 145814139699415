import React, {createContext, useEffect, useState} from "react";
import Fetcher from "../data/Fetcher";
import {GameData} from "../data/GameData";
import {Sprite} from "../model/Sprite";
import {Cache} from "../model/Cache";
import {Npc} from "../model/Npc";
import {Door} from "../model/Door";
import {Collectible} from "../model/Collectible";
import {AvatarData} from "../data/AvatarData";
import {LevelData} from "../data/LevelData";
import {Avatar} from "../model/Avatar";

interface GameContextType {
    cache: Cache,
    sprite: Sprite,
    getHero: (id: string) => Avatar,
    getNpc: (id: string) => Npc,
    getDoor: (id: string) => Door,
    collectibles: Collectible[],
    getCollectible: (id: string) => Collectible,
    getLevel: (id: string) => LevelData
}

export const GameContext = createContext<GameContextType | undefined>(undefined);

const Game = (props: any) => {

    console.log("GAME");

    const [gameData, setGameData] = useState<GameData | undefined>();
    const [heroesData, setHeroesData] = useState<AvatarData[] | undefined>();
    const [cache, setCache] = useState<Cache | undefined>();

    useEffect(() => {
        new Fetcher<GameData>("/data/game.json")
            .onSuccess((gameData: GameData) => setGameData(gameData))
            .fetch();
        new Fetcher<AvatarData[]>("/data/heroes.json")
            .onSuccess((heroes: AvatarData[]) => setHeroesData(heroes))
            .fetch();
    }, []);

    useEffect(() => {
        if (!gameData || !heroesData) {
            return;
        }
        Cache.fromData(gameData, heroesData).then((cache) => setCache(cache));
    }, [gameData, heroesData]);

    if (!gameData || !heroesData || !cache) {
        return;
    }

    //heroes
    const heroes = new Map<string, Avatar>();
    heroesData.forEach(hero => heroes.set(hero.id, Avatar.fromData(hero)));
    const getHero = (id: string): Avatar => {
        const hero = heroes.get(id);
        if (hero) return hero;
        throw new Error("Unable to find hero with id " + id);
    }

    //npcs
    const npcs = new Map<string, Npc>();
    gameData.npcs.forEach(npc => npcs.set(npc.id, Npc.fromData(npc)));
    const getNpc = (id: string): Npc => {
        const npc = npcs.get(id);
        if (npc) return npc;
        throw new Error("Unable to find npc with id " + id);
    }

    //doors
    const doors = new Map<string, Door>();
    gameData.doors.forEach(door => doors.set(door.id, Door.fromData(door)));
    const getDoor = (id: string): Door => {
        const door = doors.get(id);
        if (door) return door;
        throw new Error("Unable to find door with id " + id);
    }

    //collectibles
    const collectibles = new Map<string, Collectible>();
    gameData.collectibles.forEach(col => collectibles.set(col.id, Collectible.fromData(col)));
    const getCollectible = (id: string): Collectible => {
        const collectible = collectibles.get(id);
        if (collectible) return collectible;
        throw new Error("Unable to find collectible with id " + id);
    }

    //levels
    const levels = new Map<string, LevelData>();
    gameData.levels.forEach(level => levels.set(level.id, level));
    const getLevel = (id: string): LevelData => {
        const level = levels.get(id);
        if (level) return level;
        throw new Error("Unable to find level with id " + id);
    }

    const gameContext = {
        cache: cache,
        sprite: Sprite.fromData(gameData.sprite),
        getHero: getHero,
        getNpc: getNpc,
        getDoor: getDoor,
        collectibles: Array.from(collectibles.values()),
        getCollectible: getCollectible,
        getLevel: getLevel
    }

    return (
        <GameContext.Provider value={gameContext}>
            {props.children}
        </GameContext.Provider>
    );

}

export default Game;