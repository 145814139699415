import React, {useContext} from "react";
import {SessionContext} from "./Session";
import {Location} from "../model/Location";
import {Position} from "../model/Position";
import {Direction} from "../model/Direction";
import FixedImage from "./FixedImage";
import {GameContext} from "./Game";

interface CollectiblesProps {
    collectibles: Location[],
    acquireLock: () => boolean,
    walkHero: (position: Position, direction: Direction, onWalkFinished: () => void) => void
}

const Collectibles = (props: CollectiblesProps) => {

    const {getCollectible} = useContext(GameContext)!;
    const {unlock, isLocked} = useContext(SessionContext)!;

    const {
        collectibles,
        acquireLock,
        walkHero,
    } = props;

    const renderCollectibles = () => {
        return collectibles.map(((npc, index) => renderCollectible(npc, index)));
    }

    const renderCollectible = (location: Location, index: number) => {

        const collectible = getCollectible(location.id);

        if (!isLocked(collectible.unlocks)) {
            return;
        }

        const onWalkFinish = () => {
            unlock(collectible.unlocks);
        }

        const onClickHandler = () => {
            if (!acquireLock()) return;
            walkHero(location.getEngagingPosition(), location.getEngagingDirection(), onWalkFinish);
        }

        return <FixedImage key={index}
                           image={collectible.image}
                           scale={collectible.scale}
                           position={location.position}
                           onClickHandler={onClickHandler}/>
    }

    return renderCollectibles();

}

export default Collectibles;